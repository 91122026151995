import { ref, computed } from 'vue'
import { API_BASE, API_ENDPOINT_PORTAL_SEND_ACTIVATION_REMINDER } from '@/constants/index.js'
import { mapAccount } from '@/utils/mappers/AccountMap'
import { useErrorReporter } from '@/composables/useErrorReporter'
import type { MappedAccountData } from '@/components/account/types'
import { useToast } from '@/composables/useToast'
import { useNotes } from '@/composables/useNotes'

const accountData = ref<MappedAccountData>()
const lastAccountId = ref('')
const fullAddress = computed(() => {
	const streetAddress = accountData.value?.address || ''
	const city = accountData.value?.city || ''
	const state = accountData.value?.state || ''
	const country = 'USA'
	if (!streetAddress || !city || !state) {
		return ''
	}
	return `${streetAddress}, ${city}, ${state}, ${country}`
})
const fullName = computed(() => `${accountData.value?.firstName} ${accountData.value?.lastName}`)
const accountName = computed(() => {
	return {
		title: accountData.value?.title || null,
		first: accountData.value?.firstName || '',
		middle: accountData.value?.middleName || null,
		last: accountData.value?.lastName || '',
		suffix: accountData.value?.suffix || null
	}
})
const loading = ref(true)

export function useAccount () {
	const { bugsnagReport } = useErrorReporter()

	const refetchAccount = async () => {
		if (lastAccountId.value) {
			if (typeof lastAccountId.value === 'number') {
				lastAccountId.value = (lastAccountId.value as number)?.toString()
			}
			await fetchAccount(lastAccountId.value)
		}
	}

	const fetchAccount = async (accountId: string | number) => {
		// fetchAccount is a private function, getAccount is the public function that has checks to avoid refetching
		loading.value = true

		if (typeof accountId === 'number') {
			lastAccountId.value = accountId?.toString()
		} else {
			lastAccountId.value = accountId
		}

		await $fetch(`${API_BASE}/portal/accounts/${accountId}`, {
			method: 'GET',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					loading.value = false
					bugsnagReport({
						error: new Error(response._data?.error || response._data?.errors?.[0]),
						showToast: true,
						toast: {
							title: 'Error fetching account data',
							message: response._data?.errors?.[0]?.title
						}
					})
					throw new Error(response._data?.error || response._data?.errors?.[0])
				}
				loading.value = false
				accountData.value = mapAccount(response._data)
			}
		}).catch((e) => {
			bugsnagReport({
				error: e,
				showToast: true,
				toast: {
					title: 'Error fetching account data'
				}
			})
		})
		loading.value = false
	}

	const getAccount = async (accountId: string) => {
		if (accountId === lastAccountId.value) { return }
		await fetchAccount(accountId)
	}

	const handleSendActivationReminder = async (accountId: string) => {
		if (!accountId) { return }
		const { addToast } = useToast()
		const { getNotes } = useNotes()
		loading.value = true
		await $fetch(`${API_BASE}${API_ENDPOINT_PORTAL_SEND_ACTIVATION_REMINDER.replace('{account-id}', accountId)}`, {
			method: 'POST',
			credentials: 'include',
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0]?.title)
				}
				/* TODO i18n */
				addToast({
					title: 'Success',
					message: 'An activation reminder has been sent to the user',
					notificationType: 'success'
				})
				getNotes(accountId, true)
			}
		}).catch((e) => {
			/* TODO i18n */
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getInvoices - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error',
					message: e instanceof Error ? e.message : 'Error sending activation reminder',
					notificationType: 'error'
				},
				user: { id: accountId, name: fullName.value, emailId: String(accountData.value?.emailId) || '' }
			})
		}).finally(() => {
			loading.value = false
		})
	}

	return {
		accountData,
		fullAddress,
		fullName,
		accountName,
		getAccount,
		refetchAccount,
		loading,
		handleSendActivationReminder
	}
}
